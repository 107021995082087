import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { Mutation } from 'react-apollo';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import verifyEmailMutation from 'graphql/mutations/verifyEmail.graphql';

@withRouter
export default class extends Component {
  constructor(props) {
    super(props);

    const parsedSearch = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const key = parsedSearch.key || '';

    this.state = {
      key,
      headless: !!key,
      initialized: false,
    };
  }

  componentDidMount() {
    if (this.state.headless) {
      this.verifyEmail();
    }
    this.setState({
      initialized: true,
    });
  }

  handleError = () => {
    this.props.onError('Произошла ошибка. Попробуйте позднее.');
  };

  handleComplete = ({ verifyEmail: { success, error } }) => {
    if (success) {
      this.props.onSuccess();
      return;
    }

    this.props.onError(error);
  };

  handleChange = (event) => {
    this.setState({
      key: event.target.value,
    });
  };

  render() {
    return (
      <Mutation
        mutation={verifyEmailMutation}
        variables={{ key: this.state.key }}
        onError={this.handleError}
        onCompleted={this.handleComplete}
      >
        {(verifyEmail, { loading }) => {
          this.verifyEmail = verifyEmail;

          if (this.state.headless) {
            if (!this.state.initialized || loading) {
              return <p>Загрузка...</p>;
            }
            return null;
          }

          return (
            <Form
              method="get"
              onSubmit={(event) => {
                event.preventDefault();
                this.props.clearError();
                verifyEmail();
              }}
            >
              <Form.Control>
                <Form.Label htmlFor="key">Код</Form.Label>
                <Form.Input
                  type="text"
                  id="key"
                  name="key"
                  value={this.state.key}
                  onChange={this.handleChange}
                  disabled={loading}
                  autoComplete="off"
                />
              </Form.Control>
              <Button type="submit" primary disabled={loading || !this.state.initialized}>
                {loading || !this.state.initialized ? 'Загрузка...' : 'Подтвердить'}
              </Button>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}
