import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import MainTemplate from 'components/templates/MainTemplate';
import Alert from 'components/atoms/Alert';
import ButtonLink from 'components/atoms/ButtonLink';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import Form from './Form';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Wrapper = styled.div`
  max-width: 40em;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const LoginLink = styled(ButtonLink).attrs({
  to: '/signin',
  children: 'Войти',
  neutral: true,
  inline: true,
})``;

export default class VerifyEmailPage extends Component {
  state = {
    error: null,
    success: false,
  };

  clearError = () => {
    this.setState({
      error: null,
    });
  };

  handleError = (error) => {
    this.setState({
      error,
    });
  };

  handleSuccess = () => {
    this.setState({
      success: true,
    });
  };

  render() {
    return (
      <MainTemplate>
        <Helmet>
          <title>{title.verifyEmail}</title>
          <meta name="keywords" content={metaKeywords.verifyEmail} />
          <meta name="description" content={metaDescription.verifyEmail} />
        </Helmet>
        <Title>{heading.verifyEmail}</Title>
        <Wrapper>
          {this.state.error && <StyledAlert type="error">{this.state.error}</StyledAlert>}
          {this.state.success && (
            <>
              <StyledAlert type="success">
                Ваш E-mail подтверждён! Приятного пользования. Для продолжения выполните вход.
              </StyledAlert>
              <LoginLink />
            </>
          )}
          {!this.state.success && (
            <Form
              onError={this.handleError}
              onSuccess={this.handleSuccess}
              clearError={this.clearError}
            />
          )}
        </Wrapper>
      </MainTemplate>
    );
  }
}
